<template>
  <div class="row" ref="mediaForm">


    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="title"
              rules="required"
              name="The title"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Title *"
                        name="title"
                        fou
                        v-model="formData.title">
              </fg-input>
            </ValidationProvider>

            <ValidationProvider
              vid="ident"
              rules="required"
              name="The ident"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :disabled="editMode === true"
                        :error="failed ? errors[0]: null"
                        label="Ident *"
                        name="ident"
                        fou
                        v-model="formData.ident">
              </fg-input>
            </ValidationProvider>

            <fg-text type="text"
                     label="Description"
                     name="description"
                     fou
                     :rows="5"
                     v-model="formData.description">
            </fg-text>


          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/mediaInfo/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Select, Option} from 'element-ui';
import {mapActions} from "vuex";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        title: "",
        description: "",
        ident: "",
      },

    };
  },

  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.mediaForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Media Info";
      this.getMediaInfo();
    } else {
      this.editMode = false;
      this.formTitle = "Add Media Info";
      this.loader.hide();
    }
  },


  methods: {

    ...mapActions({
      indexAction: 'global/index'
    }),

    getMediaInfo() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("mediaInfo/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Media Info Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("mediaInfo/update/" + this.id, this.formData);
        successMessage = "Media Info Item Updated Successfully";
      } else {
        request = this.axios.post("mediaInfo/create", this.formData);
        successMessage = "Media Info Item Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        });
        this.indexAction().then(() => {
          this.$router.push("/mediaInfo/list");
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      });
    }

  }
}
</script>

<style>
</style>
